import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";

import SwiperCore, { Navigation } from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation]);

const Gallery = () => {
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const data = [
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhpalRB-CKWZad2ehC-Nn6RYNqx6OrxYZLYkzsaqOucD-OVJw6uCEG2eR9ih3_RJ5xXsRvxBJhHz2sW_TBK37sIqIdilLN3vy12yLJY9dzKXFbrl888_uucrFsy9aFow-SF3F_bx6ClWAEax66l_mf9oNmB27WBCaci3ygJlSESwRaGyRF1Mt9FWkMbb1Q/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.58.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjnnRjlbHdykc3J__DQ1cSRFN2TM5dO_Q9rP8VQyc_i4m1qSs3YnWn96nZ1XFNf8h6MfBn4j8yetl861-XIeHVPGL5JeWRhuA5XvNx4Q8ldI1-9zOOgKPs949APy8Dyzk2bREM-aeX1K-y6tKTHmreaVTJB5F9ruVqbXZttkhLNtzCzBp8cUWq07wUZGMs/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.47.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi5z1Z3TljJiKWb96p51kBwjKWN7urTMeTCBISjCFGkGRIgAF5uHtUQdrnfC-RsddUqQ4VF4bO9NYxyqqruaXToikFp3aSoLVAvyS3cKl7wi7PxXfaV2JB8PaUlxtSWFHy02C9SjS9-9jmPXEJU6R5AwFeaFVQ_Ljzpizm-HMbV9MkHZSz5RcedIYnjMss/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.45%20(1).jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgzOcp1je8l2XF9Hwbo_HFb44yNrSolyKaKQcJbRdo2qy4B3s2jIr5YnZfIi0BSEQ08nyHeGAdWfOxOZnFOuqh1GZfLQ1ylh6U-UBjHzxYti4-b5UJ0AJ3uHIWcgZ8POFYcu0KSTjyxbBulgJlTUZNIjtp-YavlGMDx-Ml4gNMsQgfdIdEdIyVLGioWZXg/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.00.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgocpwJp_mJeELsBo_bYplM0eok_XirJLDqDq7V_6kgmDBmDgaIScNbH_tCh_V7lbOXDXZ1ThMPlf4R5yfxOgIIzPOFK13gDHg6GuTFc6GXrpdaQ7belolwN4EVZWhh1dtKLXTCaNQ09veAiZCxdkzL_pvRlMmN3bfquSapwdC_xY-v8bh5azrjvbSIQTo/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.00%20(2).jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgIf1jFyDRUnNTjQLxBPHHkzvmwMpCQ7kvw4t3buf73hkyyyQyhVnJvhYqA3VBw6pyVu3hbd_BQKhaXmIpUnEP7HXbBK6EUxa_aBdQllXCtYEjKp69RHl7PNuFFh87IZ7W0EhjmhBT30zt-AcbHmnL_-Wy3BvnDnmrMR6sfj3Syqd2L6iUxmf5BiUEGrOM/s320/WhatsApp%20Image%202023-08-21%20at%2009.33.00%20(1).jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgrAwWG-Y3xhcaoBk_vkhRD9rZhvYy9Ks4SbmTmbZlLtB3G9BMJNlDO3jogWHB5Dg3mmn7zzt5zC5bEiKFkNVll6z7l5NmMgkr6ZnWsHDrA0NhT0RFoNXJJuy8PELWrlpbeHW5L_oTKWglvSPQtcO0NLKv-k-1F3fXkdD4r_Hl0QS18S3iLY5gkGRXtavQ/s320/WhatsApp%20Image%202023-08-21%20at%2009.32.59%20(1).jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgd0LnGa743F_Ybf1JXStMp8GPhe8MBFPqSdOXVVbSzrJJdo2wOmzba5KWEIHw61f31lq-iHDx5kVZwEBJn3ly-bbRMHfoLkgXVwbyQrHuLaJnTpookAIT6_ADH_EcmRbMBosDmh5T3BmGBxeFstzfQhw4sSPNfiT3hPTDuW_9QiuxV-rX5Wq7Lun2EqJI/s320/WhatsApp%20Image%202023-08-21%20at%2009.32.58.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh08M4GuYiNdfdCGuGHW4Fx163-kN9c5omvpYcBKrReCXPfoPbGgEMA86rKU4RgkLgJKRlQzmW_Qc3U5DjS1bBmpd08ZWFEVudevIzB6qV5Sfpo_Bp5wYDTFk2-X_HPqB_kNNvGoeVjpkxsTY9eNZVeUY5E3gy9fxTUOQY3VrbJqWFoM6FAQQu5aeiuo4w/s320/WhatsApp%20Image%202023-08-21%20at%2009.32.46.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjADN1L0dDehPLED00VQXx1juFFs6IGmtFiUhGyXUK8ramVWM8IcRtz9BIDVl4wJQTjl72kHzUk0ZOorb9cabHpXzVSn7PlNX_Q3ouFxgNIS7h25wB3jbar43jHnAcs6UsIUX8ov_FIqxFflZcWKmQZkOf5RxRoO7nspvx9AvSJ3CVNRVc1xDr6D3GN83c/s320/WhatsApp%20Image%202023-08-21%20at%2009.32.39.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh2SX84miHk7x65v8wskIWtIHweCKqFIgFIL0FGWachjfmmB4zzAflxBa6G-t7LvxWMn8hqnf_e7BCGSVrL3sJezoqtVEd1Kw3_89ZDiWfSA9ePjbaeA1GX9r21BQuj3uejfxVYkCk_4HEC7jPscgTJYAWkk6np3FWwPnsmMdfd9G5wRba8c8uodqAASk8/w436-h640/WhatsApp%20Image%202024-04-20%20at%2007.59.43%20(1).jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEheF00YAqBjepnUdWxKKwiEQbWoKDYkkfqbhRY4yJNktQhP_nA63ZdeXG9a8h1tREX2wC_FSS-_Z-vyY8_NVfwykwwQEsUnv0rKBCpBvs15Rk5POUCR0Eg-Or9bAhEtwcXYH7bv4APHX6umqaq9Jhh7Cjj9_dyQ40MPx8rxW95mpjtjk304NcVWMwaJyho/w450-h640/WhatsApp%20Image%202024-04-20%20at%2007.59.47.jpeg",
    },
  ];

  return (
    <div
      style={{
        background: "#E2C2B3",
      }}
      id="work"
      className=" md:block"
    >
      <div className=" w-full flex  flex-col items-center py-20  ">
        <div
          className="font-base black flex  gotham md:text-5xl text-3xl pb-8 text-white"
          data-aos="fade-up"
        >
          Gallery
        </div>
        <div className="md:max-w-[1020px] w-full">
          <div className="w-full  flex">
            <div
              className="hidden swiper-button md:flex cursor-pointer  items-center "
              ref={prevRef}
              style={{
                height: 300,
              }}
            >
              <div className="h-10 w-10 flex text-white hover:text-red-500 hover:border-red-500 hover:bg-red-500 hover:bg-opacity-30 items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
                <BsChevronLeft />
              </div>
            </div>
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              autoplay={{ delay: 3000 }}
              loop={true}
              updateOnWindowResize
              onSwiper={setSwiper}
              navigation={{
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              }}
              observer
              observerParents
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              {data.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className=" group flex flex-col  relative overflow-hidden  rounded-lg border border-black/60">
                    <div className="">
                      <div>
                        <img
                          alt="rg_images"
                          src={item.thumbnail} // use normal <img> attributes as props
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          className="object-cover cursor-pointer  pic"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="swiper-button hidden md:flex cursor-pointer  items-center"
              ref={nextRef}
              style={{
                height: 300,
              }}
            >
              <div className="h-10 w-10  hover:text-red-500 hover:border-red-500 hover:bg-red-500 hover:bg-opacity-30 flex text-white items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
                <BsChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
